<template>
    <footer class="sticky-footer footer">
            <div class="container">
                <div class="footer-inner-content">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <p class="m-0 copy-content py-3"><span v-html="copyrights"/></p>
                        </div>
                        <div class="col-md-6">
                        </div>
                    </div>
                </div>
            </div>
        </footer>
</template>
<script>
  export default {
      name : "Footer",
      computed : {
          copyrights : function(){
              let sActiveYear = new Date().getFullYear();
              return "Copyright &copy; Voyzant API "+sActiveYear;
          }
      }
  }
</script>
